import { FC } from 'react'

import { ACTIVITY_TYPE } from '@/constants/activity'
import { useNetworkBasedLinkFactories } from '@/hooks'

const Description: FC<{ activity: any; blsKeyToTxHashes: any }> = ({
  activity,
  blsKeyToTxHashes
}) => {
  const { makeEtherscanLink } = useNetworkBasedLinkFactories()

  switch (activity.key) {
    case ACTIVITY_TYPE.ETH_DEPOSITED_BY_STAKER:
      return (
        <span className="description">
          Deposited ETH{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.LP_BURNED_FOR_ETH:
      return (
        <span className="description">
          Burned Giant LP Token for ETHs{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.GIANT_LP_SWAPPED:
      return (
        <span className="description">
          Swapped Giant LP Token for LSD Pool Token{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.SMART_WALLET_CREATED:
      return (
        <span className="description">
          Created a smart wallet{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.NEW_VALIDATOR_REGISTERED:
      return (
        <span className="description">
          Registered a new validator{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.LP_TOKEN_ISSUED:
      return (
        <span className="description">
          Minted new LP Token{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.LP_TOKEN_MINTED:
      return (
        <span className="description">
          Minted LP Token{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.KNOT_STAKED:
      return (
        <span className="description">
          Staked a new KNOT{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.STAKEHOUSE_CREATED:
      return (
        <span className="description">
          Minted dETH and SLOT tokens{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.STAKEHOUSE_JOINED:
      return (
        <span className="description">
          Minted dETH and SLOT tokens{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.DETH_CLAIMED:
      return (
        <span className="description">
          Claimed dETH{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.FEES_AND_MEV_CLAIMED:
      return (
        <span className="description">
          Claimed Fees and MEV rewards{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    case ACTIVITY_TYPE.NODE_RUNNER_REWARDS_CLAIMED:
      return (
        <span className="description">
          Claimed node operator Fees and MEV rewards{' '}
          <a
            href={makeEtherscanLink(activity.id)}
            target="_blank"
            rel="noreferrer"
            className={'text-primary'}>
            (check TX here)
          </a>
        </span>
      )
    default:
      return <></>
  }
}

export default Description
