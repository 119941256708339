export enum ACTIVITY_TYPE {
  ETH_DEPOSITED_BY_STAKER = 'ETH_DEPOSITED_BY_STAKER',
  LP_BURNED_FOR_ETH = 'LP_BURNED_FOR_ETH',
  GIANT_LP_SWAPPED = 'GIANT_LP_SWAPPED',
  SMART_WALLET_CREATED = 'SMART_WALLET_CREATED',
  NEW_VALIDATOR_REGISTERED = 'NEW_VALIDATOR_REGISTERED',
  LP_TOKEN_ISSUED = 'LP_TOKEN_ISSUED',
  LP_TOKEN_MINTED = 'LP_TOKEN_MINTED',
  KNOT_STAKED = 'KNOT_STAKED',
  STAKEHOUSE_CREATED = 'STAKEHOUSE_CREATED',
  STAKEHOUSE_JOINED = 'STAKEHOUSE_JOINED',
  DETH_CLAIMED = 'DETH_CLAIMED',
  FEES_AND_MEV_CLAIMED = 'FEES_AND_MEV_CLAIMED',
  NODE_RUNNER_REWARDS_CLAIMED = 'NODE_RUNNER_REWARDS_CLAIMED'
}
